import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import AuthPage from './components/AuthPage';
import OnboardingSteps from './components/OnboardingSteps';
import PartnerBenefits from './components/PartnerBenefits';
import FAQSection from './components/FAQSection';
import TrustSection from './components/TrustSection';
import PartnerOnBoardingSteps from './components/PartnerOnBoardingSteps';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
        
          <Route path="/" element={
            <>
              <AuthPage />
              <OnboardingSteps />
              <PartnerBenefits />
              <FAQSection />
              <TrustSection/>
            </>
          } />
          
          {/* Additional Page Routes */}
          <Route path="/onboarding" element={<OnboardingSteps />} />
          <Route path="/benefits" element={<PartnerBenefits />} />
          <Route path="/faq" element={<FAQSection />} />
          <Route path="/partner-onboarding" element={<PartnerOnBoardingSteps />} />
          {/* 404 Not Found Route */}
          <Route 
            path="*" 
            element={
              <div className="flex items-center justify-center h-screen text-2xl">
                404 - Page Not Found
              </div>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;