import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const PartnerOnBoardingSteps = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    aadharNumber: '',
    panCardNumber: '',
    phoneNumber: '',
    otp: '',
    otpVerified: false,
    termsAgreed: false,
    agreementFee: false,
    confidentiality: false,
  });

  // Input Change Handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Checkbox Handler
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  // OTP Verification
  const handleOtpVerification = () => {
    if (formData.otp === '1234') {
      alert('OTP verified successfully!');
      setFormData({ ...formData, otpVerified: true });
    } else {
      alert('Invalid OTP!');
    }
  };

  // Validation Logic
  const validateStep1 = () => {
    const { name, location, aadharNumber, panCardNumber, phoneNumber, otpVerified } = formData;
    if (!name || !location || !aadharNumber || !panCardNumber || !phoneNumber || !otpVerified) {
      alert('Please fill out all fields and verify OTP before proceeding.');
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    const { termsAgreed, agreementFee, confidentiality } = formData;
    if (!termsAgreed || !agreementFee || !confidentiality) {
      alert('You must agree to all terms before proceeding.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://localhost:3000/partner-onboarding', formData);
      alert(response.data.message);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your application.');
    }
  };

  return (
    <motion.div
      style={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <h1 style={styles.title}>Event Partner Onboarding</h1>

      {/* Step 1 */}
      {step === 1 && (
        <motion.div
          style={styles.step}
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 60 }}
        >
          <h2 style={styles.heading}>Step 1: Personal Information</h2>
          <input
            style={styles.input}
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            placeholder="Location"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            placeholder="Aadhar Number"
            name="aadharNumber"
            value={formData.aadharNumber}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            placeholder="PAN Card Number"
            name="panCardNumber"
            value={formData.panCardNumber}
            onChange={handleChange}
          />
          <div style={styles.otpContainer}>
  <input
    style={styles.phoneInput}
    placeholder="Phone Number"
    name="phoneNumber"
    value={formData.phoneNumber}
    onChange={handleChange}
  />
  <button style={styles.getOtpButton} onClick={() => alert('OTP sent!')}>
    Get OTP
  </button>
</div>
          <div style={styles.otpContainer}>
            <input
              style={styles.input}
              placeholder="Enter OTP (1234)"
              name="otp"
              value={formData.otp}
              onChange={handleChange}
            />
            <button style={styles.otpButton} onClick={handleOtpVerification}>
              Verify OTP
            </button>
          </div>
          <button style={styles.nextButton} onClick={() => validateStep1() && setStep(2)}>
            Next
          </button>
        </motion.div>
      )}

      {/* Step 2 */}
      {step === 2 && (
        <motion.div
          style={styles.step}
          initial={{ x: '100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 60 }}
        >
          <h2 style={styles.heading}>Step 2: Agreement Terms</h2>
          <label style={styles.checkboxLabel}>
            <input type="checkbox" name="termsAgreed" onChange={handleCheckbox} /> I agree to the
            terms and conditions.
          </label>
          <label style={styles.checkboxLabel}>
            <input type="checkbox" name="agreementFee" onChange={handleCheckbox} /> I agree to pay
            the 1-year agreement fee.
          </label>
          <label style={styles.checkboxLabel}>
            <input type="checkbox" name="confidentiality" onChange={handleCheckbox} /> I will keep
            all information confidential.
          </label>
          <button style={styles.nextButton} onClick={() => validateStep2() && setStep(3)}>
            Next
          </button>
        </motion.div>
      )}

      {/* Step 3 */}
      {step === 3 && (
        <motion.div
          style={styles.step}
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 style={styles.heading}>Step 3: Submit</h2>
          <button style={styles.submitButton} onClick={handleSubmit}>
            Submit Application
          </button>
        </motion.div>
      )}
    </motion.div>
  );
};

const styles = {
    container: {
      display: 'flex',              // Using flexbox to center content
      flexDirection: 'column',      // Stack elements vertically
      justifyContent: 'center',     // Center vertically
      alignItems: 'center',         // Center horizontally
      minHeight: '100vh',           // Full viewport height
      paddingTop: '50px',           // Add top padding
      maxWidth: '600px',            // Maximum width of the container
      margin: '0 auto',             // Center container horizontally
      padding: '20px',              // Padding inside the container
      borderRadius: '12px',         // Rounded corners
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Shadow for depth
      backgroundColor: '#fff8f2',   // Background color
      color: '#333',                // Text color
      fontFamily: "'Roboto', sans-serif", // Font
    },
    title: {
      textAlign: 'center',
      color: '#ff6600',
      fontSize: '32px',
      marginBottom: '20px',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    heading: {
      fontSize: '24px',
      color: '#ff6600',
      marginBottom: '10px',
    },
    input: {
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      fontSize: '16px',
      outline: 'none',
    },
    otpContainer: {
      display: 'flex',
      gap: '10px',
    },
    getOtpButton: {
        backgroundColor: '#ff6600',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        padding: '10px 15px',
        cursor: 'pointer',
      },
    
    otpButton: {
      backgroundColor: '#ff6600',
      color: '#fff',
      border: 'none',
      borderRadius: '8px',
      padding: '10px 15px',
      cursor: 'pointer',
    },
    nextButton: {
      backgroundColor: '#ff6600',
      color: '#fff',
      border: 'none',
      borderRadius: '8px',
      padding: '10px',
      cursor: 'pointer',
    },
    submitButton: {
      backgroundColor: '#ff4500',
      color: '#fff',
      border: 'none',
      borderRadius: '8px',
      padding: '12px',
      fontSize: '18px',
      cursor: 'pointer',
    },
    checkboxLabel: {
      fontSize: '16px',
    },
  };
  

export default PartnerOnBoardingSteps;
