import React, { useState } from 'react';
import { Mail, Phone, Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-lg z-50">
      <div className="container mx-auto px-6 py-5 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <img 
            src="/shatapartnerlogo.png" 
            alt="Company Logo" 
            className="h-12 w-auto"
          />
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-6">
          <a 
            href="mailto:launch@company.com" 
            className="flex items-center bg-orange-500 text-white px-5 py-2.5 rounded-full hover:bg-orange-600 transition-all duration-300"
          >
            <Mail className="mr-2 h-5 w-5" />
            Launch Mail
          </a>
          <a 
            href="/contact" 
            className="flex items-center bg-orange-500 text-white px-5 py-2.5 rounded-full hover:bg-orange-600 transition-all duration-300"
          >
            <Phone className="mr-2 h-5 w-5" />
            Contact Support
          </a>
        </nav>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button 
            onClick={toggleMenu} 
            className="text-gray-800 focus:outline-none"
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-full left-0 w-full bg-white shadow-md md:hidden">
            <nav className="flex flex-col items-center py-6 space-y-4">
              <a 
                href="mailto:launch@company.com" 
                className="flex items-center bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600"
                onClick={toggleMenu}
              >
                <Mail className="mr-2 h-5 w-5" />
                Launch Mail
              </a>
              <a 
                href="/contact" 
                className="flex items-center bg-gray-100 text-gray-800 px-6 py-3 rounded-full hover:bg-gray-200"
                onClick={toggleMenu}
              >
                <Phone className="mr-2 h-5 w-5" />
                Contact Support
              </a>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;